import { BusinessAreaDTO } from "@src/api/business-areas";
import { DebitorDocumentResponse, Document } from "@src/api/documents";
import { SymfonyFields } from "@src/api/interfaces/Symfony";
import { JobChannelDTO, JobChannelSubChannel } from "@src/api/jobChannels";
import { JobMarket } from "@src/api/jobMarket";
import { JobReport } from "@src/api/jobReports";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { JobEditFormInputs } from "@src/components/forms/jobs/JobEditForm";
import { Customer } from "@src/types/DashboardTypes";
import argsBuilder from "@src/utils/args-builder";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { delete_, get, post, put } from "@src/utils/request";
import React from "react";

export const JobsContext = React.createContext<Job[]>([]);

export const fetchJobs = async (
    sort?: string | undefined,
    page?: number,
    pageSize?: number,
    freeText?: string,
    customer?: CinnamonID,
    archived?: number | null,
    runtimeStart?: string,
    runtimeEnd?: string,
): Promise<ApiResponse<Job>> => {
    const args = argsBuilder({
        archived,
        customer,
        freeText,
        page,
        pageSize,
        runtimeEnd,
        runtimeStart,
        sort,
    });

    return await get<ApiResponse<Job>>(`/internalApi/jobs${args}`, { cache: true, ttl: 5 * MINUTE });
};

export const createJob = async (body: JobEditFormInputs): Promise<void> => {
    await post("/internalApi/jobs", { body });
    await burstJobCaches();
};

export const updateJob = async (jobId: CinnamonID, body: JobEditFormInputs): Promise<void> => {
    await put(`/internalApi/jobs/${jobId}`, { body });
    await burstJobCaches();
};

export const deleteJob = async (jobId: CinnamonID): Promise<void> => {
    await delete_(`/internalApi/jobs/${jobId}`);
    await burstJobCaches();
};

export const duplicateJob = async (jobId: CinnamonID): Promise<Job> => {
    const duplicatedJob = await post<Job>(`/internalApi/jobs/${jobId}/duplicate`);
    await burstJobCaches();
    return duplicatedJob;
};

export const burstJobCaches = async () => deleteRelatedItemsFromCacheStorage("/internalApi/jobs");

export const fetchJobDebitorDocuments = async (job: CinnamonID): Promise<Document[]> => {
    const args = argsBuilder({ job });
    const docs = await get<DebitorDocumentResponse>(`/internalApi/debitors/documents${args}`);
    return docs.items;
};

export const fetchJobReportsCreditorDocuments = async (job: CinnamonID) => {
    const args = argsBuilder({ category: "creditor", job });
    const resp = await get<ApiResponse<JobReport>>(`/internalApi/statistics/job_reportings${args}`);
    return resp.items;
};

interface JobCustomizations {
    aprimoSync?: boolean;
    ninehundredFiftyJob?: boolean;
    currency?: string;
    businessArea?: BusinessAreaDTO;
    channel?: JobChannelDTO;
    market?: JobMarket;
    subchannel?: JobChannelSubChannel;
    sapLedgeAccount?: string; // in combination with ninehundredFiftyJob
    costTransfer?: string; // in combination with ninehundredFiftyJob
}

export interface Job extends SymfonyFields, Partial<JobCustomizations> {
    account: string | null;
    actualCost: string;
    archived: boolean;
    budget: number;
    contact: string;
    currency: string;
    customer: Customer;
    estimatedCosts: string;
    expenseAccount: string | null;
    externalId: CinnamonID | null;
    id: CinnamonID;
    name: string;
    numId: CinnamonID;
    notes: string;
    number: string;
    runtimeEnd: string;
    runtimeStart: string;
    suppliers: Customer[];
}
