import { burstAddressCaches } from "@src/api/addresses";
import { burstBusinessAreasCache } from "@src/api/business-areas";
import { burstCompanyCaches } from "@src/api/companies";
import { burstContactCaches } from "@src/api/contacts";
import { burstCreditorDocumentCaches, burstDebitorDocumentsCaches } from "@src/api/documents";
import { burstJobCaches } from "@src/api/jobs";
import { burstPaymentCaches } from "@src/api/payments";
import { burstProductCaches, burstProductCategoryCaches } from "@src/api/products";
import { burstRolesCache } from "@src/api/roles";
import { burstSapCache } from "@src/api/sap";
import { burstTeamsCache } from "@src/api/teams";
import { burstApiUserCaches } from "@src/api/users";
import { burstVatCaches } from "@src/api/vats";
import useFDWebsocket from "@src/hooks/fd-websocket";
import log from "@src/utils/logger";
import { useEffect } from "react";

/**
 * This component listens to websocket messages and bursts the cache of the corresponding data.
 *
 * Note:
 * There is a similar component called LockSnackbarHandler that listens to websocket messages and displays a snackbar
 * when a document is locked or unlocked. Please don't mix them up.
 *
 * @constructor
 */
const WebsocketToCacheCleaner = () => {
    const { lastJsonMessage } = useFDWebsocket();

    useEffect(() => {
        if (lastJsonMessage !== null) {
            switch (lastJsonMessage.channel) {
                case "debitor_document":
                    burstDebitorDocumentsCaches().then(() =>
                        log.info("burstDebitorDocumentsCaches because of websocket message"),
                    );
                    break;
                case "creditor_document":
                    burstCreditorDocumentCaches().then(() =>
                        log.info("burstCreditorDocumentCaches because of websocket message"),
                    );
                    break;
                case "job":
                    burstJobCaches().then(() => log.info("burstJobCaches because of websocket message"));
                    break;
                case "debitors":
                case "creditors":
                case "debitor-creditor":
                    // Contains a special case for company addresses and contacts. Happens when the user
                    // opens and closes a company address or contact.
                    if (lastJsonMessage.payload.title.search("address") != -1) {
                        burstAddressCaches().then(() => log.info("burstAddressCaches because of websocket message"));
                        break;
                    } else if (lastJsonMessage.payload.title.search("contact") != -1) {
                        burstContactCaches().then(() => log.info("burstContactCaches because of websocket message"));
                        break;
                    }

                    burstCompanyCaches().then(() => log.info("burstCompanyCaches because of websocket message"));
                    break;
                case "product":
                    burstProductCaches().then(() => log.info("burstProductCaches because of websocket message"));
                    break;
                case "product_category":
                    burstProductCategoryCaches().then(() =>
                        log.info("burstProductCategoryCaches because of websocket message"),
                    );
                    break;
                case "payment":
                    burstPaymentCaches().then(() => log.info("burstPaymentCaches because of websocket message"));
                    break;
                case "business_areas":
                    burstBusinessAreasCache().then(() =>
                        log.info("burstBusinessAreasCache because of websocket message"),
                    );
                    break;
                case "vats":
                    burstVatCaches().then(() => log.info("burstVatCaches because of websocket message"));
                    break;
                case "users":
                    if (lastJsonMessage.payload.title.search("api_user") != -1) {
                        burstApiUserCaches().then(() => log.info("burstApiUserCaches because of websocket message"));
                        break;
                    }

                    burstApiUserCaches().then(() => log.info("burstUserCaches because of websocket message"));
                    break;
                case "teams":
                    burstTeamsCache().then(() => log.info("burstTeamsCache because of websocket message"));
                    break;
                case "roles":
                    burstRolesCache().then(() => log.info("burstRolesCache because of websocket message"));
                    break;
                case "sap":
                    burstSapCache().then(() => log.info("burstSapCache because of websocket message"));
                    break;
            }
        }
    }, [lastJsonMessage]);

    return null;
};

export default WebsocketToCacheCleaner;
