import { FeatureData, fetchFeatures } from "@src/api/feature-flags";
import { useStateWithDataFetcher } from "@src/hooks/data-fetcher";
import log from "@src/utils/logger";
import React, { createContext, FC } from "react";

interface FeatureFlagProviderProps {
    children: React.ReactNode;
}

interface FeatureFlagsContextType {
    features: FeatureData;
}

export const initialState: FeatureData = {
    feature_ae: false,
    feature_aprimo_a1: false,
    feature_business_area_from_job_in_creditor_doc: false,
    feature_creditor_account: false,
    feature_creditor_offer: false,
    feature_currency_by_document: false,
    feature_datev: false,
    feature_debitor_document_currency: false,
    feature_discount_for_positions: false,
    feature_identity: false,
    feature_job_business_area: false,
    feature_job_channels: false,
    feature_job_comment: false,
    feature_job_currency: false,
    feature_job_documents_details: false,
    feature_job_markets: false,
    feature_job_ninehundredFiftyJob: false,
    feature_job_reportings: false,
    feature_mediadesk: false,
    feature_onward_document: false,
    feature_sap: false,
    feature_upload: false,
    feature_zterm: false,
};

export const FeatureFlags = createContext<FeatureFlagsContextType>({ features: initialState });

export const FeatureFlagsProvider: FC<FeatureFlagProviderProps> = ({ children }) => {
    const [features] = useStateWithDataFetcher<FeatureData>(initialState, async () => {
        try {
            log.debug("Fetching feature flags");
            const features = await fetchFeatures();

            // HARD CODED FEATURES FOR A1 TODO: TO BE REMOVED
            if (CINNAMON_VENDOR == "a1") {
                features.feature_job_channels = true;
                features.feature_identity = true;
                features.feature_debitor_document_currency = true;
                features.feature_job_markets = true;
                features.feature_aprimo_a1 = true;
                features.feature_business_area_from_job_in_creditor_doc = true;
                features.feature_currency_by_document = true;
                features.feature_discount_for_positions = true;
                features.feature_job_reportings = true;
                features.feature_ae = true;
                features.feature_creditor_offer = true;
                features.feature_onward_document = true;
                features.feature_upload = true;
                features.feature_job_documents_details = true;
                features.feature_job_currency = true;
                features.feature_job_business_area = true;
                features.feature_job_ninehundredFiftyJob = true;
            } else {
                features.feature_creditor_account = true;
            }

            return features;
        } catch (error) {
            log.error("Failed to fetch feature flags" + error);
            return initialState;
        }
    });

    return <FeatureFlags.Provider value={{ features }}>{children}</FeatureFlags.Provider>;
};
