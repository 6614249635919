import { DateRange } from "@mui/x-date-pickers-pro";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { FilterStatus, FilterType } from "@src/pages/payments/PaymentsShared";
import { RootState } from "@src/store";
import dayjs, { Dayjs } from "dayjs";

export interface PaymentsState {
    search?: string;
    companyId?: CinnamonID | null;
    type?: FilterType;
    status?: FilterStatus;
    dateRange?: DateRange<Dayjs>;
}

const initialState: PaymentsState = {
    companyId: null,
    dateRange: [dayjs().subtract(1, "month"), dayjs()],
    search: "",
    status: FilterStatus.All,
    type: FilterType.All,
};

export const paymentsSlice = createSlice({
    initialState,
    name: "payments",
    reducers: {
        setCompanyId: (state, action: PayloadAction<CinnamonID | null>) => {
            state.companyId = action.payload;
        },
        setDateRange: (state, action: PayloadAction<DateRange<Dayjs>>) => {
            state.dateRange = action.payload;
        },
        setSearch: (state, action: PayloadAction<string>) => {
            state.search = action.payload;
        },
        setStatus: (state, action: PayloadAction<FilterStatus>) => {
            state.status = action.payload;
        },
        setType: (state, action: PayloadAction<FilterType>) => {
            state.type = action.payload;
        },
    },
});

export const { setType, setStatus, setDateRange, setSearch, setCompanyId } = paymentsSlice.actions;

export const selectPayments = (state: RootState) => state.payments;

export default paymentsSlice.reducer;
