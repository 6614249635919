import { Link, SxProps, Theme, Typography } from "@mui/material";
import { Company } from "@src/api/companies";
import { CreditorDocumentType, DebitorDocumentType } from "@src/api/documents";
import { CinnamonID } from "@src/api/types/CinnamonID";
import useProfileHasPermission from "@src/hooks/profile-permissions";
import { Permission } from "@src/utils/permission";
import React from "react";

export enum DocumentType {
    JOB = "job",
    DEBITOR_DOC = "debitorDoc",
    CREDITOR_DOC = "creditorDoc",
    CREDITOR = "creditor",
    DEBITOR = "debitor",
}

interface DebitorDocumentLinkProps {
    children?: string | JSX.Element | JSX.Element[] | "() => JSX.Element";
    document: {
        id: CinnamonID;
        companyId?: CinnamonID;
        company?: Company;
        type: DebitorDocumentType;
    };
    title?: string;
    sx?: SxProps<Theme> | undefined;
}

interface CreditorsDebitorsEditProps {
    children?: string | JSX.Element | JSX.Element[] | "() => JSX.Element";
    document: {
        id: CinnamonID;
        companyId: CinnamonID;
        company: Company;
        type: CreditorDocumentType;
    };
    title?: string;
    sx?: SxProps<Theme> | undefined;
}

interface DocumentLinkProps {
    children?: string | JSX.Element | JSX.Element[] | "() => JSX.Element";
    id: CinnamonID;
    title?: string;
    documentType: DocumentType;
    companyId?: CinnamonID;
    documentSubType?: CreditorDocumentType | DebitorDocumentType;
    sx?: SxProps<Theme> | undefined;
}

interface LinkProps {
    children?: string | JSX.Element | JSX.Element[] | "() => JSX.Element";
    id: CinnamonID;
    title?: string;
    sx?: SxProps<Theme> | undefined;
}

/**
 * Shorthand for a DocumentLink to a debitor document.
 */
export const DebitorDocumentLink: React.FC<DebitorDocumentLinkProps> = ({ children, document, title, sx }) => {
    return (
        <DocumentLink
            companyId={document.company ? document.company.id : document.companyId}
            documentSubType={document.type}
            documentType={DocumentType.DEBITOR_DOC}
            id={document.id}
            sx={sx}
            title={title}
        >
            {children || title}
        </DocumentLink>
    );
};

/**
 * Shorthand for a DocumentLink to a creditor document.
 */
export const CreditorDocumentLink: React.FC<CreditorsDebitorsEditProps> = ({ children, document, title, sx }) => {
    return (
        <DocumentLink
            companyId={document.company ? document.company.id : document.companyId}
            documentSubType={document.type}
            documentType={DocumentType.CREDITOR_DOC}
            id={document.id}
            sx={sx}
            title={title}
        >
            {children || title}
        </DocumentLink>
    );
};

/**
 * Shorthand for a Job Link.
 */
export const JobLink: React.FC<LinkProps> = ({ children, id, title, sx }) => {
    return (
        <DocumentLink
            documentType={DocumentType.JOB}
            id={id}
            sx={sx}
            title={title}
        >
            {children || title}
        </DocumentLink>
    );
};

/**
 * Shorthand for a Job Link.
 */
export const DebitorLink: React.FC<LinkProps> = ({ children, id, title, sx }) => {
    return (
        <DocumentLink
            documentType={DocumentType.DEBITOR}
            id={id}
            sx={sx}
            title={title}
        >
            {children || title}
        </DocumentLink>
    );
};

/**
 * Shorthand for a Job Link.
 */
export const CreditorLink: React.FC<LinkProps> = ({ children, id, title, sx }) => {
    return (
        <DocumentLink
            documentType={DocumentType.CREDITOR}
            id={id}
            sx={sx}
            title={title}
        >
            {children || title}
        </DocumentLink>
    );
};

/**
 * A link to a document. The link is generated based on the document type. The type of link can be specified
 * with the variant property.
 */
const DocumentLink: React.FC<DocumentLinkProps> = ({
    children,
    id,
    title,
    documentType,
    companyId,
    documentSubType,
    sx,
}) => {
    const hasPermissionsHook = useProfileHasPermission();

    let navLink = "";
    let hasPermissions = false;

    switch (documentType) {
        case DocumentType.DEBITOR: {
            if (hasPermissionsHook(Permission.DEBITOR_VIEW)) {
                hasPermissions = true;
            }
            navLink = `/debitors/list/view/${id}`;
            break;
        }
        case DocumentType.CREDITOR: {
            if (hasPermissionsHook(Permission.CREDITOR_VIEW)) {
                hasPermissions = true;
            }
            navLink = `/creditors/list/view/${id}`;
            break;
        }
        case DocumentType.DEBITOR_DOC: {
            if (hasPermissionsHook(Permission.DEBITOR_DOC_VIEW)) {
                hasPermissions = true;
            }
            navLink = `/debitors/document/${companyId}/${documentSubType}/${id}`;
            break;
        }
        case DocumentType.CREDITOR_DOC: {
            if (hasPermissionsHook(Permission.CREDITOR_DOC_VIEW)) {
                hasPermissions = true;
            }

            // The CreditorDocumentEdit component expects the documentSubType to be "creditor_order" instead of "order"
            // TODO: Check if this can be solved otherwise. Preferably by making the backend and frontend both
            // use the convention to use "order" instead of "creditor_order". Maybe I'm wrong and creditor_order
            // is only used in the frontend!
            if (documentSubType == "order") {
                documentSubType = "creditor_order";
            }

            navLink = `/creditors/document/${companyId}/${documentSubType}/${id}`;
            break;
        }
        case DocumentType.JOB: {
            if (hasPermissionsHook(Permission.JOB_VIEW)) {
                hasPermissions = true;
            }
            navLink = `/jobs/list/${id}/view`;
            break;
        }
    }

    return hasPermissions ? (
        <Link
            href={navLink}
            sx={{
                ...sx,
            }}
            title={title}
            underline={"always"}
        >
            {children || title}
        </Link>
    ) : (
        <Typography
            fontSize="inherit"
            variant="inherit"
        >
            {title}
        </Typography>
    );
};

export default DocumentLink;
