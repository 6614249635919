export enum FilterType {
    All = "all",
    Bankwire = "bankwire",
    DebitCard = "debit",
    EcCard = "ec",
    CreditCard = "credit",
    Cash = "cash",
    Charge = "charge",
}

export enum FilterStatus {
    All = "all",
    Open = "open",
    Charged = "charged",
}
