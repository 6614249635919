import { CreditorDocumentType, DebitorDocumentType } from "@src/api/documents";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { CompanyDTO } from "@src/types/Payment";
import argsBuilder from "@src/utils/args-builder";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { get, post } from "@src/utils/request";
import React from "react";

export enum ZTermsType {
    percentage = "percentage",
    fixed = "fixed",
}

export interface SAPDocument {
    company: CompanyDTO;
    createdBy: string;
    updatedBy: string;
    currentNo: number;
    date: string; //ISO DATE STRING
    documentYear: number;
    id: CinnamonID;
    listType: SAPType;
    onwardedFrom: string | null;
    paginateNo: string;
    paymentDueSum: number;
    po: string;
    sum: number;
    sumDiscount: number;
    sumTotal: number;
    sumVat: number;
    type: CreditorDocumentType | DebitorDocumentType;
}

export interface SAPDocumentsResponse {
    addedUpSum: number;
    addedUpSumTotal: number;
    count: number;
    items: SAPDocument[];
    total: number;
}

export interface SapLogsResponse {
    count: number;
    exports: SapLog[];
}

export interface SapLog {
    closedAt: string;
    dateFrom: string;
    dateTo: string;
    documents: number;
}

export type SAPCompanyCode = 101 | 136;
export type SAPType = "creditor" | "debitor";

export const SAPContext = React.createContext<SAPDocument[]>([]);

export const fetchSAPDocuments = async (
    companyCode: SAPCompanyCode,
    type: SAPType,
    user: string,
    page: number,
    pageSize: number,
): Promise<SAPDocumentsResponse> => {
    const args = argsBuilder({ companyCode, page, pageSize, type, user });
    return await get(`/internalApi/sap/documents${args}`, { cache: true, ttl: 5 * MINUTE });
};

export const fetchSapLogs = async (start: string, end: string): Promise<SapLogsResponse> => {
    const args = argsBuilder({ end, start });
    return await get(`/internalApi/sap/logs${args}`, { cache: true, ttl: 5 * MINUTE });
};

export const fetchSapExportToken = async (companyCode: SAPCompanyCode, documents: CinnamonID[], type: SAPType) => {
    const res = await post<{ token: string }>("/internalApi/sap/export", {
        body: { companyCode, documents, test: false, type },
    });
    await burstSapCache();
    return res;
};

export const fetchSapTestExportToken = (companyCode: SAPCompanyCode, documents: CinnamonID[], type: SAPType) => {
    return post<{ token: string }>("/internalApi/sap/testExport", {
        body: {
            companyCode,
            documents,
            test: true,
            type,
        },
    });
};

export const burstSapCache = async () => deleteRelatedItemsFromCacheStorage("/internalApi/sap");
