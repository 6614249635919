import { createTheme, PaletteMode } from "@mui/material";
import i18n from "i18next";

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        outlined: true;
    }
}

// Extent the palette to include a highlightGrey color
declare module "@mui/material/styles" {
    interface Palette {
        highlightGrey: Palette["primary"];
        corporateColor: Palette["primary"];
    }

    interface PaletteOptions {
        highlightGrey?: PaletteOptions["primary"];
        corporateColor?: PaletteOptions["primary"];
    }
}

export const getDesignTokens = (mode: PaletteMode) => ({
    components: {
        MuiAlert: {
            styleOverrides: {
                root: {
                    fontWeight: 800,
                },
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                noOptionsText: i18n.t("terms.no-options"),
            },
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    "& .MuiBadge-badge": {
                        right: "-8px",
                    },
                },
            },
        },
        MuiDateRangePickerInput: {
            styleOverrides: {
                root: {
                    // A custom class to add to daterangepickers in order to adjust their height to elements with size="small"
                    "& .smallElementsHeight": {
                        "& .MuiInputBase-sizeSmall": {
                            height: "31px",
                        },
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        ">.MuiListItemIcon-root": {
                            color: "#FFFFFF",
                        },
                        backgroundColor: "#296361",
                        color: "#FFFFFF",
                    },
                    "&:hover": {
                        ">.MuiListItemIcon-root": {
                            color: "#FFFFFF",
                        },
                        backgroundColor: "#619696",
                        color: "#FFFFFF",
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "0.9rem",
                    fontWeight: 400,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontSize: "0.750rem",
                },
                body2: {
                    fontSize: "0.750rem",
                },
            },
        },
        mode,
        ...(mode === "light"
            ? {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            "&.MuiButton-outlinedPrimary": {
                                backgroundColor: "#FFFFFF",
                            },
                            "&:hover": {
                                boxShadow: "none",
                            },
                            ".MuiButton-startIcon": {
                                // Customize the styling of the specific subelement here
                                marginRight: "5px",
                            },
                            boxShadow: "none",
                        },
                    },
                },
                MuiButtonGroup: {
                    styleOverrides: {
                        root: {
                            boxShadow: "none",
                        },
                    },
                },
                MuiCssBaseline: {
                    styleOverrides: {
                        html: {
                            backgroundColor: "#EEEEEE",
                            body: {
                                backgroundColor: "#EEEEEE",
                            },
                        },
                    },
                },
                MuiDataGrid: {
                    styleOverrides: {
                        aggregationColumnHeaderLabel: {
                            bottom: 0,
                            fontSize: "0.555rem",
                        },
                        cell: {
                            "&:not(:last-child)": {
                                borderRight: "1px solid #E0E0E0",
                            },
                            fontSize: "0.6875rem",
                        },
                        columnHeader: {
                            "&:not(:last-child)": {
                                borderRight: "1px solid #E0E0E0",
                            },
                            fontSize: "0.6875rem",
                            paddingBottom: "15px",
                        },
                        columnSeparator: {
                            opacity: "0 !important",
                        },
                        footerCell: {
                            fontWeight: "bold",
                        },
                        footerContainer: {
                            fontSize: "0.6875rem",
                        },
                        root: {
                            backgroundColor: "#FFFFFF",
                        },
                        row: {
                            "&:nth-of-type(even)": {
                                "&:hover": {
                                    backgroundColor: "#DEDEDE",
                                },
                                backgroundColor: "#EEEEEE",
                            },
                        },
                        toolbarContainer: {
                            ".MuiButton-startIcon": {
                                // Customize the styling of the specific subelement here
                                marginRight: "2px",
                            },
                            gap: "16px",
                        },
                    },
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            // Remove arrows from number input (Chrome, Edge, Safari)
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                            },
                            // Remove arrows from number input (Firefox)
                            "& input[type=number]": {
                                mozappearance: "textfield",
                            },
                            backgroundColor: "#FFFFFF",
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            color: "rgba(140,140,140,0.8)",
                            zIndex: "10",
                        },
                    },
                },
                MuiTableContainer: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#FFFFFF",
                        },
                    },
                },
                MuiTablePagination: {
                    styleOverrides: {
                        displayedRows: {
                            fontSize: "0.750rem",
                        },
                        root: {
                            fontSize: "0.750rem",
                        },
                        selectLabel: {
                            fontSize: "0.685rem",
                        },
                    },
                },
            }
            : {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            "&.MuiButton-containedPrimary": {
                                "& .MuiSvgIcon-root": {
                                    color: "white",
                                },
                                color: "white",
                            },
                            "&.MuiButton-outlinedPrimary": {
                                backgroundColor: "#424242",
                            },
                            "&:hover": {
                                boxShadow: "none",
                            },
                            ".MuiButton-startIcon": {
                                marginRight: "5px",
                            },
                            boxShadow: "none",
                        },
                    },
                },
                MuiButtonGroup: {
                    styleOverrides: {
                        root: {
                            "& .MuiButton-root": {
                                color: "white",
                            },
                            "& .MuiSvgIcon-root": {
                                color: "white",
                            },
                            boxShadow: "none",
                        },
                    },
                },
                MuiCssBaseline: {
                    styleOverrides: {
                        html: {
                            backgroundColor: "#565656",
                            body: {
                                backgroundColor: "#565656",
                            },
                        },
                    },
                },
                MuiDataGrid: {
                    styleOverrides: {
                        aggregationColumnHeaderLabel: {
                            bottom: 0,
                            fontSize: "0.555rem",
                        },
                        cell: {
                            "&:not(:last-child)": {
                                borderRight: "1px solid #5e5e5e",
                            },
                            fontSize: "0.6875rem",
                        },
                        columnHeader: {
                            "&:not(:last-child)": {
                                borderRight: "1px solid #5e5e5e",
                            },
                            fontSize: "0.6875rem",
                            paddingBottom: "15px",
                        },
                        columnSeparator: {
                            opacity: "0 !important",
                        },
                        footerCell: {
                            fontWeight: "bold",
                        },
                        footerContainer: {
                            background: "#262626 !important",
                            fontSize: "0.6875rem",
                        },
                        pinnedColumns: {
                            backgroundColor: "#262626",
                        },
                        pinnedRows: {
                            backgroundColor: "#262626",
                        },
                        root: {
                            a: {
                                "&:hover": {
                                    color: "#35BDB8",
                                },
                                color: "#35BDB8",
                            },
                            backgroundColor: "#424242",
                        },
                        row: {
                            "&:nth-of-type(even)": {
                                "&:hover": {
                                    backgroundColor: "#2F2F2F",
                                },
                                backgroundColor: "#383838",
                            },
                        },
                    },
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#424242",
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            color: "#35BDB8",
                            zIndex: "10",
                        },
                    },
                },
                MuiListSubheader: {
                    styleOverrides: {
                        root: {
                            color: "#FFFFFF",
                        },
                    },
                },
                MuiModal: {
                    styleOverrides: {
                        root: {
                            "& .MuiDialog-paper": {
                                backgroundColor: "#262626 !important",
                            },
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#262626",
                        },
                    },
                },
                MuiSvgIcon: {
                    styleOverrides: {
                        root: {
                            color: "#35BDB8",
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            a: {
                                "&:hover": {
                                    color: "#35BDB8",
                                },
                                color: "#35BDB8",
                            },
                        },
                    },
                },
                MuiTableContainer: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#424242",
                        },
                    },
                },
            }),
    },
    palette: {
        mode,
        ...(mode === "light"
            ? {
                background: {
                    default: "#EEEEEE",
                    paper: "#FFF",
                },
                components: {
                    MuiButton: {
                        variants: [
                            {
                                props: { variant: "contained" },
                                style: {
                                    "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF",
                                    },
                                    border: "1px solid white",
                                    color: "#FFFFFF",
                                },
                            },
                            {
                                props: { variant: "outlined" },
                                style: {
                                    border: `2px dashed ${"#FF0000"}`,
                                    textTransform: "none",
                                },
                            },
                            {
                                props: { color: "secondary", variant: "outlined" },
                                style: {
                                    border: `4px dashed ${"#FF0000"}`,
                                },
                            },
                        ],
                    },
                },
                contrastText: "#FFF", //button text white instead of black
                corporateColor: {
                    main: "#f2075d",
                },
                divider: "rgba(0, 0, 0, 0.12)",
                error: {
                    contrastText: "#FFF",
                    main: "#FF0000",
                },
                highlightGrey: {
                    main: "#ECECEC",
                },
                primary: {
                    contrastText: "#FFF",
                    dark: "#1E3634",
                    light: "#35BDB8",
                    main: "#296361",
                },
                text: {
                    primary: "rgba(0, 0, 0, 0.87)",
                    secondary: "rgba(0, 0, 0, 0.54)",
                },
                warning: {
                    contrastText: "#FFF",
                    main: "#E89B0B",
                },
            }
            : {
                background: {
                    default: "#565656",
                    paper: "#191919",
                },
                components: {
                    MuiButton: {
                        variants: [
                            {
                                props: { variant: "outlined" },
                                style: {
                                    border: `2px dashed ${"#FF0000"}`,
                                    textTransform: "none",
                                },
                            },
                            {
                                props: { color: "secondary", variant: "outlined" },
                                style: {
                                    border: `4px dashed ${"#FF0000"}`,
                                },
                            },
                        ],
                    },
                    MuiCssBaseline: {
                        styleOverrides: {
                            html: {
                                backgroundColor: "#565656",
                            },
                        },
                    },
                    MuiDataGrid: {
                        styleOverrides: {
                            footerContainer: {
                                background: "#00FFB2",
                            },
                        },
                    },
                },
                contrastText: "#FFF",
                corporateColor: {
                    main: "#f2075d",
                },
                divider: "rgba(255, 255, 255, 0.22)", //button text white instead of black
                error: {
                    contrastText: "#FFF",
                    main: "#FF0000",
                },
                grey: {
                    [100]: "#191919",
                    [200]: "#232323",
                    [300]: "#393838",
                    [400]: "#434343",
                    [500]: "#5c5c5c",
                    [600]: "#737373",
                    [700]: "#9c9c9c",
                    [800]: "#bcbcbc",
                    [900]: "#d1d1d1",
                },
                highlightGrey: {
                    main: "#383838",
                },
                primary: {
                    contrastText: "rgba(0, 0, 0, 0.87)",
                    dark: "#21B7B2",
                    light: "#6CEFEB",
                    main: "#21B7B2",
                },
                text: {
                    primary: "#FFFFFF",
                    secondary: "#21B7B2",
                },
                warning: {
                    contrastText: "#FFF",
                    light: "#E7B24F",
                    main: "#E89B0B",
                },
            }),
    },
});
const DefaultTheme = createTheme(getDesignTokens("light"), ["light"]);

export default DefaultTheme;
