import { selectProfile } from "@src/features/profile/profile-slice";
import { useAppSelector } from "@src/hooks/redux";
import { hasPermission, Permission } from "@src/utils/permission";
import { useCallback } from "react";

/**
 * useProfileHasPermission is a hook that returns a function which checks the given permission against the current
 * user's profile.
 */
const useProfileHasPermission = () => {
    const profile = useAppSelector(selectProfile);

    return useCallback(
        (permission: Permission) => {
            return hasPermission(permission, profile);
        },
        [profile],
    );
};

export default useProfileHasPermission;
