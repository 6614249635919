import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/store";

export enum ScanSize {
    Small = 0,
    Medium = 1,
    Large = 2,
}

export interface ScanStore {
    detailsDialogSize?: ScanSize;
}

const initialState: ScanStore = {
    detailsDialogSize: ScanSize.Medium,
};

export const scansSlice = createSlice({
    initialState,
    name: "scansSlice",
    reducers: {
        setScanDetailsDialogSize: (state, action: PayloadAction<ScanSize>) => {
            state.detailsDialogSize = action.payload;
        },
    },
});

export const { setScanDetailsDialogSize } = scansSlice.actions;

export const selectScans = (state: RootState) => state.scans;

export default scansSlice.reducer;
