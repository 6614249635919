/**
 * @description Permission enum contains all permissions that have an effect on the frontend. Most permissions
 * are not explicitly relevant for the frontend code. Most permissions are taken care of by the backend. The navigation
 * is also composed by the backend and the frontend displays only options given by the backend. The permissions
 * that are relevant for the frontend are the ones that are used to hide or show buttons or other elements.
 */
export enum Permission {
    JOB_EDIT = "job_edit",
    JOB_CREATE = "job_create",
    JOB_VIEW = "job_view",
    JOB_REPORTINGS_VIEW = "job_reportings_view",
    DEBITOR_DOC_EDIT = "debitor_document_edit",
    DEBITOR_DOC_VIEW = "debitor_document_view",
    CREDITOR_DOC_EDIT = "creditor_document_edit",
    CREDITOR_DOC_VIEW = "creditor_document_view",
    PRODUCT_EDIT = "product_edit",
    PRODUCT_CREATE = "product_create",
    PRODUCT_CATEGORY_EDIT = "product_category_edit",
    PRODUCT_CATEGORY_CREATE = "product_category_create",
    TEXT_SNIPPET_CREATE = "text_snippet_create",
    TEXT_SNIPPET_DELETE = "text_snippet_delete",
    TEXT_SNIPPET_EDIT = "text_snippet_edit",
    ROLE_EDIT = "role_edit",
    USER_EDIT = "user_edit",
    USER_DELETE = "user_delete",
    USER_VIEW = "user_view",
    VAT_EDIT = "vat_edit",
    ZTERM_EDIT = "zterm_edit",
    TEAM_EDIT = "team_edit",
    CREDITOR_EDIT = "creditor_edit",
    CREDITOR_VIEW = "creditor_view",
    PAYMENT_EDIT = "payment_edit",
    DEBITOR_EDIT = "debitor_edit",
    DEBITOR_VIEW = "debitor_view",
    DEBITOR_DOC_REOPEN = "debitor_document_reopen",
    DEBITOR_DOC_CLOSE = "debitor_document_close",
    CREDITOR_DOC_REOPEN = "creditor_document_reopen",
    CREDITOR_DOC_CLOSE = "creditor_document_close",
    DEBITOR_DOCUMENT_APPROVE_RESET = "debitor_document_approve_reset",
    DEBITOR_DOCUMENT_APPROVE_1 = "debitor_document_approve_1",
    DEBITOR_DOCUMENT_APPROVE_2 = "debitor_document_approve_2",
    DEBITOR_DOCUMENT_APPROVE_3 = "debitor_document_approve_3",
    CREDITOR_DOCUMENT_APPROVE_RESET = "creditor_document_item_approve_reset",
    CREDITOR_DOCUMENT_APPROVE_1 = "creditor_document_item_approve_1",
    CREDITOR_DOCUMENT_APPROVE_2 = "creditor_document_item_approve_2",
    DEBITOR_DOCUMENT_COMMENT_DELETE = "debitor_document_comment_delete",
}

interface Profile {
    role: string;
    permissions: string[];
}

export function hasPermission(permission: Permission, profile: Profile): boolean {
    if (profile.role === "role_admin") {
        return true;
    }

    return profile.permissions.includes(permission);
}

/** For more information regarding permissions, see:
 * https://digitalcontrol.atlassian.net/wiki/spaces/FAK/pages/3071901699/Berechtigungen
 * https://docs.google.com/spreadsheets/d/11YssRzmJ6Xz8z5UY6YEuYd3zhZTDK2DEdj6m9Y13GAI/edit?usp=sharing
 */
