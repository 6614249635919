import { SymfonyFields } from "@src/api/interfaces/Symfony";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { ProductCategoryEditorInputs } from "@src/components/forms/productcategory/ProductCategoryForm";
import argsBuilder from "@src/utils/args-builder";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { get, post, put } from "@src/utils/request";
import React from "react";

export const ProductsContext = React.createContext<Product[]>([]);

export const fetchProducts = async (): Promise<Product[]> => {
    const res = await get<ApiResponse<Product>>("/internalApi/products", { cache: true, ttl: 5 * MINUTE });
    return res.items;
};

interface ProductAPIParameters {
    page: number;
    pageSize: number;
    freeText: string;
}

export const fetchProductsWithParameters = async (parameter: ProductAPIParameters): Promise<ApiResponse<Product>> => {
    return get<ApiResponse<Product>>(
        `/internalApi/products${argsBuilder({
            freeText: parameter.freeText,
            page: parameter.page,
            pageSize: parameter.pageSize,
        })}`,
        { cache: true, ttl: 5 * MINUTE },
    );
};

export const fetchProduct = async (productID: CinnamonID): Promise<Product> => {
    return await get<Product>(`/internalApi/products/${productID}`, { cache: true, ttl: 5 * MINUTE });
};

export interface Product extends SymfonyFields {
    id: CinnamonID;
    name: string;
    shortName: string;
    number: string;
    description: string;
    category: ProductCategory | null;
    account: number;
    price: number;
    vat: {
        id: CinnamonID;
        name: string;
        currentValue: string;
    };
}

export interface ProductDTO {
    id: CinnamonID;
    name: string;
    number: string | number;
    price: number;
    shortName: string;
}

export interface ProductInputForms {
    id: CinnamonID;
    name: string;
    shortName: string;
    number: string;
    description: string;
    category: CinnamonID;
    account: number;
    price: number;
    vat: string;
}

export const updateProduct = async (id: CinnamonID, data: ProductInputForms): Promise<ProductCategory> => {
    const product = await put<ProductInputForms>(`/internalApi/products/${id}`, { body: data });
    await burstProductCaches();
    return product;
};

export const createProduct = async (data: ProductInputForms): Promise<ProductInputForms> => {
    const product = await post<ProductInputForms>("/internalApi/products", { body: data });
    await burstProductCaches();
    return product;
};

export const burstProductCaches = async () => deleteRelatedItemsFromCacheStorage("/internalApi/products");

export interface ProductCategoriesAPIParameters {
    page: number;
    pageSize: number;
    freeText: string;
}

export const fetchAllProductCategories = async (): Promise<ProductCategory[]> => {
    const res = await get<ApiResponse<ProductCategory>>("/internalApi/productCategories", {
        cache: true,
        ttl: 5 * MINUTE,
    });
    return res.items;
};

export const fetchProductCategories = async (parameter: {
    freeText: string;
    page: number;
    pageSize: number;
}): Promise<ApiResponse<ProductCategory>> => {
    return await get<ApiResponse<ProductCategory>>(
        `/internalApi/productCategories${argsBuilder({
            freeText: parameter.freeText,
            page: parameter.page,
            pageSize: parameter.pageSize,
        })}`,
        { cache: true, ttl: 5 * MINUTE },
    );
};

export const fetchProductCategory = async (id: CinnamonID): Promise<ProductCategory> => {
    return await get<ProductCategory>(`/internalApi/productCategories/${id}`);
};

export const updateProductCategory = async (
    id: CinnamonID,
    data: ProductCategoryEditorInputs,
): Promise<ProductCategory> => {
    const products = await put<ProductCategory>(`/internalApi/productCategories/${id}`, { body: data });
    await burstProductCategoryCaches();
    return products;
};

export const createProductCategory = async (data: ProductCategoryEditorInputs): Promise<ProductCategory> => {
    const productCategory = await post<ProductCategory>("/internalApi/productCategories", { body: data });
    await burstProductCategoryCaches();
    return productCategory;
};

export const burstProductCategoryCaches = async () =>
    deleteRelatedItemsFromCacheStorage("/internalApi/productCategories");

export interface ProductCategory extends SymfonyFields {
    id: CinnamonID | null;
    name: string;
}
